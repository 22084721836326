// Import CSS
import styles from '../css/app.pcss';

import "node_modules/@fancyapps/ui/dist/fancybox.css";
import '../css/modules/fancybox.pcss';
/* ------------------------------------------------
4X:
Accessibility Library
------------------------------------------------*/
// import whatsock4x from './libs/4X/4X.Max.js';
// import whatsock4x from 'node_modules/apex4x/4X/4X.Max.js';

/* ------------------------------------------------
CORE:
Load any critical scripts first, including functionality
that exists on every page.
------------------------------------------------*/
import globalEvents from './modules/globals/events.js';
import accessibility from './modules/globals/accessibility.js';
import animations from './modules/globals/animations.js';
import burgerMenu from './modules/globals/hamburger_menu.js';
import searchbox from './modules/globals/searchbox.js';


// Lazy Load images
import LazyLoad from "vanilla-lazyload";
const lazyContent = new LazyLoad({});


/* ==========================================================================
========================================================================== */

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
