import { toggleAriaExpanded, addTabIndex, removeTabIndex  } from './accessibility.js';
import { swapClasses  } from './tools.js';

// Global
global.searchOpen = false;

// Search Button
const triggerSearchParent = document.querySelectorAll('.open-search-btn');
const triggerSearchBtn = document.querySelectorAll('.open-search-btn > button');
const searchField = document.getElementById('s');

// Search Button Icon & Close Icon within Button
const searchBtn = document.querySelectorAll('.search-icon');
const closeBtn = document.querySelectorAll('.search-close-icon');

// Burger Nav Buttons
const openNavParent = document.querySelectorAll('.open-nav-btn');
const openNavBtn = document.querySelectorAll('.open-nav-btn > button');
const navigation = document.querySelectorAll('.navigation');

// The Searchbar & Input
const searchbar = document.querySelectorAll('.searchbar');
const searchinput = document.querySelectorAll('.searchinput');
const submitButton = document.querySelectorAll('.searchsubmit');

const inputSearchIcon = document.querySelectorAll('.input-search-icon');
const inputClear = document.querySelectorAll('.input-clear-btn');


if (triggerSearchBtn && searchbar && searchBtn && closeBtn && openNavBtn) {

    for (var i = triggerSearchBtn.length - 1; i >= 0; i--) {

        triggerSearchBtn[i].addEventListener("click", function( e ){

            // Hide Navigation if Open
            if(navigation[0].classList.contains('translate-y-99')){
               closeNavigation();
            }

            // Slide in Searchbar
            toggleSearchBar();
        });
    };

    function closeNavigation(){
        var bars = openNavBtn[0].querySelectorAll('span');
        if(bars.length == 2){
            if(bars[0] && bars[1]){
                bars[0].classList.remove('animate-burgertop');
                bars[0].classList.remove('animate-burgertop_reverse');
                bars[1].classList.remove('animate-burgerbottom');
                bars[1].classList.remove('animate-burgerbottom_reverse');
            }
        }

        swapClasses( navigation[0], 'translate-y-0', 'translate-y-99');

        // Add a slight delay to the search bar to ensure seamless transitions.
        searchbar[0].classList.add('delay-300');
        searchbar[0].addEventListener("transitionend", () => {
            searchbar[0].classList.remove('delay-300');
        });

        global.menuOpen = false;
    }

    function toggleSearchBar(){
        swapClasses( openNavParent[0], 'block', 'hidden');

        if(window.innerWidth < 908){
            var idx = 0;
        }
        else{
            var idx = 1;
        }


        // Update Form Focus state
        if(searchField && searchbar[0].classList.contains('translate-y-0') ){
            removeTabIndex('searchinput');
            removeTabIndex('searchsubmit');
            searchField.focus();
            global.searchOpen = true;

            triggerSearchBtn[ idx ].ariaLabel= "Close Search Form";
        }
        else{
            addTabIndex('searchinput');
            addTabIndex('searchsubmit');
            triggerSearchBtn[ idx ].focus();

            triggerSearchBtn[ idx ].ariaLabel= "Open Search Form";
        }

        swapClasses( searchbar[0], 'pointer-events-none', 'pointer-events-all');
        swapClasses( searchbar[0], 'translate-y-0', 'translate-y-99');

        for (var j = searchBtn.length - 1; j >= 0; j--) {
            if(searchBtn[ j ]) swapClasses( searchBtn[ j ], 'block', 'hidden');
            if(closeBtn[ j ]) swapClasses( closeBtn[ j ], 'block', 'hidden');
        };
    }

    function resetSearchInput(){
        console.log('Reset Search Input');

        searchinput[0].classList.remove('populated');

        if( inputSearchIcon[0].classList.contains('hidden') ) {
            swapClasses( inputSearchIcon[0], 'hidden', 'block');
        }

        if( inputClear[0].classList.contains('block') ) {
            swapClasses( inputClear[0], 'block', 'hidden');
        }

        searchField.focus();
    }

    /* ------------------------------------------------------------
    List for keyboard input
    • If ESC key pressed, close menu
    ------------------------------------------------------------ */
    document.addEventListener("keyup", (e) => {
        if(global.searchOpen){
            if (e.key === "Escape") {
                toggleSearchBar();
                global.searchOpen = false;
            }
        }
    });

    /* ------------------------------------------------------------
    Listen for keyboard input in the search field so we can enable the
    "clear" button
    ------------------------------------------------------------ */
    searchinput[0].addEventListener("keyup", (e) => {
        if(global.searchOpen){

            console.log(e.key, submitButton);

            if(e.key == "Enter"){
                console.log( searchinput[0].value );
                // submitButton[0].click();
                return false;
            }

            if(searchinput[0].value.length > 0){
                if(!searchinput[0].classList.contains('populated')){
                    searchinput[0].classList.add('populated');
                    swapClasses( inputSearchIcon[0], 'block', 'hidden');
                    swapClasses( inputClear[0], 'hidden', 'block');
                }
            }
            else if(searchinput[0].value.length === 0){
                resetSearchInput();
            }

        }
    });

    inputClear[0].addEventListener("click", (e) => {
        let activeEle = document.activeElement;

        if(activeEle.classList.contains('searchinput')){
            return
        }
        else{
            e.preventDefault();

            console.log('Click Clear', inputClear[0], activeEle.classList.contains('searchinput'), e );

            searchinput[0].value = '';
            searchinput[0].focus();
        }


    });
}
else{
    console.warn('Search Bar Element missing.');
}
