document.parallaxEvent = new Event("parallax_ready", { bubbles: true, cancelable: false });


window.setTimeout(function(){
    let ninjaCheckbox = document.getElementsByClassName('nf-checked');

    console.log(ninjaCheckbox.length, typeof ninjaCheckbox);

    if (ninjaCheckbox.length) {
        console.log('ok go');

        for (var i = ninjaCheckbox.length - 1; i >= 0; i--) {
            ninjaCheckbox[i].addEventListener("focus", (e) => {
                if(e.target.id){
                    let labelId = e.target.getAttribute('aria-labelledby');
                    let label = document.getElementById( labelId );

                    if(label){
                        label.classList.add('infocus');
                    }
                }
            });

            ninjaCheckbox[i].addEventListener("blur", (e) => {
                if(e.target.id){
                    let labelId = e.target.getAttribute('aria-labelledby');
                    let label = document.getElementById( labelId );

                    if(label){
                        label.classList.remove('infocus');
                    }
                }
            });
        };
    }
    else{
        console.log('try again...');
    }
}, 2000);