/* --------------------------------------------------------------------
Import accessibility helpers
--------------------------------------------------------------------*/
import { toggleAriaExpanded, addTabIndex, removeTabIndex  } from './accessibility.js';
import { swapClasses  } from './tools.js';

// Global
global.menuOpen = false;

/* --------------------------------------------------------------------
Handle Burger Menu
--------------------------------------------------------------------*/
const bodyTag = document.getElementsByTagName('body');
const openNavBtn = document.querySelectorAll('.open-nav-btn > button');
const navigation = document.querySelectorAll('.navigation');

if (navigation && openNavBtn) {
    /* ------------------------------------------------------------
    Mobile Menu items shouldn't be tab-able until the menu is open so
    list for resizing and disable/enable them for appropriate breakpoints.
    ------------------------------------------------------------ */
    if(window.innerWidth < 908){
        addTabIndex('navlink');
        removeTabIndex('mobile-tabindex');
    }

    window.addEventListener('resize', function(){
        var bars = openNavBtn[0].querySelectorAll('span');

        if( window.outerWidth < 908 ){
            addTabIndex('navlink');
            removeTabIndex('mobile-tabindex');
        }
        else{
            removeTabIndex('navlink');
            addTabIndex('mobile-tabindex');
        }
    });

    /* ------------------------------------------------------------
    Update Focus state
    ------------------------------------------------------------ */
    openNavBtn[0].addEventListener("click", function(){
        toogleNavMenu();
    });

    function toogleNavMenu(){
        var bars = openNavBtn[0].querySelectorAll('span');

        if(bars.length == 2){
            if(bars[0] && bars[1]){
                if ( !global.menuOpen ) {
                    bars[0].classList.add('animate-burgertop');
                    bars[1].classList.add('animate-burgerbottom');

                    addTabIndex('navlink');
                }
                else{
                    bars[0].classList.remove('animate-burgertop');
                    bars[1].classList.remove('animate-burgerbottom');

                    window.setTimeout(function(){
                        bars[0].classList.add('animate-burgertop_reverse');
                        bars[0].addEventListener("animationend", () => {
                            bars[0].classList.remove('animate-burgertop_reverse');
                        });

                        bars[1].classList.add('animate-burgerbottom_reverse');
                        bars[1].addEventListener("animationend", () => {
                            bars[1].classList.remove('animate-burgerbottom_reverse');
                        });
                    }, 1);

                    removeTabIndex('navlink');
                }
            }
        }


        if(navigation[0].classList.contains('translate-y-0') ){
            removeTabIndex('navlink');
            bodyTag[0].classList.add('menu-open');
            global.menuOpen = true;
        }
        else{
            addTabIndex('navlink');
            bodyTag[0].classList.remove('menu-open');
            global.menuOpen = false;
        }

        swapClasses( navigation[0], 'translate-y-0', 'translate-y-99');
        swapClasses( navigation[0], 'h-auto', 'h-screen');
        swapClasses( navigation[0], 'overflow-auto', 'overflow-scroll');
    }

    /* ------------------------------------------------------------
    List for key board input
    • If ESC key pressed, close menu
    ------------------------------------------------------------ */
    document.addEventListener("keyup", (e) => {
        if(global.menuOpen){
            if (e.key === "Escape") {
                toogleNavMenu();
            }
        }
    });
}
else{
    console.warn('Navigation or Burger Element missing.');
}